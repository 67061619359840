<template>
  <div class="form-group color-group mb-4">
    <label class="control-label">{{ label }}</label>
    <input v-model="iValue" type="text" class="form-control mt-1" />
    <span
      :style="{ backgroundColor: iValue }"
      class="color-preview"
      @click="showModal"
    ></span>
    <ColorPickerModal
      v-if="isShow"
      v-model="iValue"
      @closed="closedColorModal"
    />
  </div>
</template>

<script>
import ColorPickerModal from '../../../common/ColorPickerModal'

export default {
  components: {
    ColorPickerModal,
  },

  props: {
    value: {
      required: false,
      type: [String],
      default: '',
    },
    label: {
      required: false,
      type: [String],
      default: '',
    },
  },

  data() {
    return {
      isShow: false,
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },

      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },

  methods: {
    showModal() {
      this.isShow = true
    },

    closedColorModal() {
      this.isShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.color-group {
  position: relative;
  .form-control {
    padding-left: 34px;
  }
  .color-preview {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 14px;
    left: 10px;
    cursor: pointer;
  }

  input {
    border: 0.0625rem solid #e7e7e7;
  }
}
</style>
