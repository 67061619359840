<template>
  <div class="form-group mb-4">
    <label class="control-label">{{ label }}</label>
    <input
      v-model="iValue"
      type="number"
      class="form-control mt-1"
      :placeholder="placeholder"
    />
    <span class="unit">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    label: {
      type: [String],
      default: '',
    },
    placeholder: {
      type: [String],
      default: '',
    },
    unit: {
      type: [String],
      default: 'px',
    },
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },

      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  .unit {
    position: absolute;
    right: 8px;
    top: 12px;
  }
  input[type='number'] {
    padding-right: 25px;
    border: 0.0625rem solid #e7e7e7;
  }
}
</style>
